import React, { Suspense } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './appRoutes';

const queryClient = new QueryClient();

import BackgroundSlider from 'react-background-slider';
// @ts-ignore Workaround for bug on react-background-slider
const Slider = BackgroundSlider['react-background-slider'].default;

import image1 from './assets/images/sea-cargo-1.jpg';
import image2 from './assets/images/sea-cargo-2.jpg';
import image3 from './assets/images/sea-cargo-3.jpg';
import image4 from './assets/images/sea-cargo-4.jpg';
import Colors from './constants/Colors';

function App() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppRoutes />
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
            <Slider
              style={{ position: 'fixed' }}
              images={[image1, image2, image3, image4]}
              duration={8}
              transition={2}
            />
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: Colors.transparent
              }}
            />
          </div>
        </Router>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
