import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import { AboutScreen } from './containers/AboutScreen/AboutScreen';
// import { ContactScreen } from './containers/ContactScreen/ContactScreen';
// import { HomeScreen } from './containers/HomeScreen/HomeScreen';
// import { ServicesScreen } from './containers/ServicesScreen/ServicesScreen';
// import { AirCargoServicesScreen } from './containers/ServicesScreen/AirCargoServicesScreen';
// import { SeaCargoServicesScreen } from './containers/ServicesScreen/SeaCargoServicesScreen';
// import { ConsolidationServicesScreen } from './containers/ServicesScreen/ConsolidationServicesScreen';
// import { CustomsClearanceServicesScreen } from './containers/ServicesScreen/CustomsClearanceServicesScreen';
// import { WarehouseServicesScreen } from './containers/ServicesScreen/WarehouseServicesScreen';
// import { ProductQualityCheckServicesScreen } from './containers/ServicesScreen/ProductQualityCheckServicesScreen';
// import { ChinaProductSourcingServicesScreen } from './containers/ServicesScreen/ChinaProductSourcingServicesScreen';
// import { SupplierPaymentServicesScreen } from './containers/ServicesScreen/SupplierPaymentServicesScreen';
// import { DoorToDoorServicesScreen } from './containers/ServicesScreen/DoorToDoorServicesScreen';

// import { ShippingProcedureScreen } from './containers/ShippingProcedureScreen/ShippingProcedureScreen';
// import { ShippingTimetableScreen } from './containers/ShippingTimetableScreen/ShippingTimetableScreen';
// import { TrackingScreen } from './containers/TrackingScreen/TrackingScreen';
// import { AirCargoCalculatorScreen } from './containers/AirCargoCalculatorScreen/AirCargoCalculatorScreen';
// import { SeaCargoCalculatorScreen } from './containers/SeaCargoCalculatorScreen/SeaCargoCalculatorScreen';

// lazy
const AboutScreen = React.lazy(() => import('./containers/AboutScreen/AboutScreen'));
const ContactScreen = React.lazy(() => import('./containers/ContactScreen/ContactScreen'));
const HomeScreen = React.lazy(() => import('./containers/HomeScreen/HomeScreen'));
const AirCargoServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/AirCargoServicesScreen')
);
const SeaCargoServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/SeaCargoServicesScreen')
);
const ConsolidationServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/ConsolidationServicesScreen')
);
const CustomsClearanceServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/CustomsClearanceServicesScreen')
);
const WarehouseServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/WarehouseServicesScreen')
);
const ProductQualityCheckServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/ProductQualityCheckServicesScreen')
);
const ChinaProductSourcingServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/ChinaProductSourcingServicesScreen')
);
const SupplierPaymentServicesScreen = React.lazy(
  () => import('./containers/ServicesScreen/SupplierPaymentServicesScreen')
);
const ShippingProcedureScreen = React.lazy(
  () => import('./containers/ShippingProcedureScreen/ShippingProcedureScreen')
);
const ShippingTimetableScreen = React.lazy(
  () => import('./containers/ShippingTimetableScreen/ShippingTimetableScreen')
);
// const TrackingScreen = React.lazy(() => import('./containers/TrackingScreen/TrackingScreen'));
const BatchTrackingScreen = React.lazy(
  () => import('./containers/BatchTrackingScreen/BatchTrackingScreen')
);

const AirCargoCalculatorScreen = React.lazy(
  () => import('./containers/AirCargoCalculatorScreen/AirCargoCalculatorScreen')
);
const SeaCargoCalculatorScreen = React.lazy(
  () => import('./containers/SeaCargoCalculatorScreen/SeaCargoCalculatorScreen')
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/about-us" element={<AboutScreen />} />

      {/* services */}
      {/* <Route path="/services" element={<ServicesScreen />} /> */}
      <Route path="/air-cargo-services" element={<AirCargoServicesScreen />} />
      <Route path="/sea-cargo-services" element={<SeaCargoServicesScreen />} />
      <Route path="/consolidation-services" element={<ConsolidationServicesScreen />} />
      <Route path="/customs-clearance-services" element={<CustomsClearanceServicesScreen />} />
      <Route path="/container-loading-supervision" element={<WarehouseServicesScreen />} />
      <Route path="/cargo-inspection-services" element={<ProductQualityCheckServicesScreen />} />
      <Route path="/product-sourcing-services" element={<ChinaProductSourcingServicesScreen />} />
      <Route
        path="/supplier-payments-facilitation-services"
        element={<SupplierPaymentServicesScreen />}
      />
      {/* <Route path="/door-to-door-services" element={<DoorToDoorServicesScreen />} /> */}

      <Route path="/shipping-procedure" element={<ShippingProcedureScreen />} />
      <Route path="/shipping-timetable" element={<ShippingTimetableScreen />} />
      <Route path="/tracking" element={<BatchTrackingScreen />} />
      <Route path="/air-cargo-calculator" element={<AirCargoCalculatorScreen />} />
      <Route path="/sea-cargo-calculator" element={<SeaCargoCalculatorScreen />} />
      <Route path="/contact-us" element={<ContactScreen />} />
    </Routes>
  );
};
